import React from 'react';
import moment from 'moment';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { MountBox, FilterMonth } from '../../../../utils/style';
/* eslint-disable */
function Month({
  month,
  year,
  currentDay,
  selectedYear,
  setDay,
  selectedDay,
  setMonth,
  currentMonth,
  setSelectedYear,
  calendar,
  currentYear,
  selectDay,
  newPreEntrty,
}) {
  const numMonth = Number(month);
  const numSelectedYear = Number(selectedYear);
  const numCurrentYear = Number(currentYear);
  const numCurrentMonth = Number(currentMonth);

  const newCurrentDay = moment(`${numSelectedYear}.${numMonth}.${currentDay}`, 'YYYY:MM:DD').isValid() ? currentDay : 1;
  const mom = moment(`${numSelectedYear}.${numMonth}.${newCurrentDay}`, 'YYYY:MM:DD');
  const offset = moment(`${numSelectedYear}.${numMonth}.01`, 'YYYY:MM:DD').isoWeekday();
  const countDays = mom.daysInMonth();
  const monthName = mom.format('MMMM');
  const arrDays = [];
  const arrNameDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

  const formattedMonth = moment(numMonth, 'M').format('MM');
  const hasAvailableSlots = year && Object.keys(year).length > 0 && year[formattedMonth]
    && Object.keys(year[formattedMonth]).length > 0
    && Object.keys(year[formattedMonth]).some((day) => {
      // Проверяем только если это текущий месяц и год
      if (numSelectedYear === numCurrentYear && numMonth === numCurrentMonth) {
        return Number(day) >= Number(currentDay) && year[formattedMonth][day].count;
      }
      return year[formattedMonth][day].count;
    });

  const changeDay = (isOpen, m, d) => {
    if (isOpen) {
      if (newPreEntrty) selectDay(year[m][d].id);
      else setDay(year[m][d].id);
    }
  };

  for (let i = 1; i < offset; i += 1) {
    arrDays.push(<li key={i + 100} />);
  }
  for (let b = 1; b <= countDays; b += 1) {
    const m = moment(numMonth, 'M').format('MM');
    const d = moment(`${numSelectedYear}.${numMonth}.${b}`, 'YYYY:MM:D').format('DD');
    const isOpen = year && year[m] && year[m][d] ? year[m][d].count : false;

    arrDays.push(<li
      key={b}
      onClick={() => changeDay(isOpen, m, d)}
      className={`item ${selectedDay === b ? 'active' : ''} ${isOpen ? 'open' : ''}`}
    >
      {b}
    </li>); // eslint-disable-next-line
  }

  const changeMonth = (increment) => {
    const newMonth = numMonth + increment;
    const isCurrentYear = numSelectedYear === numCurrentYear;

    // Проверяем возможность перехода на предыдущий месяц
    if (increment < 0 && isCurrentYear && newMonth < numCurrentMonth) {
      return;
    }

    // Переход на следующий год
    if (newMonth === 13) {
      if (!calendar[numSelectedYear + 1]) {
        return;
      }
      setSelectedYear(numSelectedYear + 1);
      setMonth(1);
      return;
    }

    // Переход на предыдущий год
    if (newMonth === 0) {
      setSelectedYear(numSelectedYear - 1);
      setMonth(12);
      return;
    }

    setMonth(newMonth);
  };

  const showPrevArrow = numSelectedYear > numCurrentYear
    || (numSelectedYear === numCurrentYear && numMonth > numCurrentMonth);

  const showNextArrow = calendar[numSelectedYear + 1]
    || (calendar[numSelectedYear] && numMonth < 12);

  return (
    <>
      <h3>Выберите день</h3>
      <MountBox>
        <FilterMonth className="center">
          <div className="filter">
            <span
              onClick={() => changeMonth(-1)}
              style={{ visibility: showPrevArrow ? 'visible' : 'hidden' }}
            >
              <FaChevronLeft />
            </span>
            <p>{monthName} {numSelectedYear}
            </p>
            <span
              onClick={() => changeMonth(1)}
              style={{ visibility: showNextArrow ? 'visible' : 'hidden' }}
            >
              <FaChevronRight />
            </span>
          </div>
        </FilterMonth>
        {!hasAvailableSlots ? (
          <div style={{
            textAlign: 'center',
            margin: '50px 0',
            color: '#666',
            fontSize: '24px',
            width: '100%',
          }}
          >
            Нет доступных слотов в этом месяце
          </div>
        ) : (
          <>
            {arrNameDays.map((el) => <li key={el} className="title">{el}</li>)}
            {arrDays}
          </>
        )}
      </MountBox>
    </>
  );
}

export default Month;
